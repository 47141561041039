//$wcmc-base: "../";

/*Uncomment the below lines when making a subtheme so that the change of directory
doesn't result in images being lost.
*/

 //$wcmc-base: "../../../../../profiles/wcmc/themes/wcmc_base/";
 //@import "../../../../../../profiles/wcmc/themes/wcmc_base/scss/_variables";
// @import "../../../../../../profiles/wcmc/themes/wcmc_base/scss/_mixins";
//$image-location: $wcmc-base + "images/";

$cornell-red: #B31B1B;

#carousel,
#two,
#three{
  float: none;
  clear: both;
}
#two{
  margin-top: 35px;
  .container{
    min-height: 400px;
  }
}
#three{
  display: block;
  width: 100%;
  min-height: 404px;
  background: url('../../images/ctabg.jpg') 0 0 no-repeat;
  background-size: cover;
}

.section-inner {
  max-width: 1140px;
  margin: 0 auto;
}

